<div class="privacy-data">
    <h3>PRIVACIDADE E PROTEÇÃO DE DADOS</h3>
    <h4>Exerça aqui os seus direitos como Titular de Dados:</h4>
    <div>
        <a routerLink="/aviso-de-privacidade">AVISO DE PRIVACIDADE</a>
        <a target="_blank" href="https://titulares.becompliance.com/webforms/75c21893-b94d-4046-842a-a0adb7c80be6/85088d1a-dbcb-488c-b6cd-66fb35085083">PORTAL DO TITULAR</a>
    </div>
    <div class="dpo">
        <span class="dpo-title"><strong>DPO</strong></span> 
        <span><strong>Leandro Rocha</strong></span> 
        <a href="mailto:privacidade@flamengo.com.br">privacidade@flamengo.com.br</a>
    </div>
</div>