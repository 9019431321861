import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MsalRedirectComponent } from '@azure/msal-angular';

import { AuthGuard, GuestGuard, MaintenanceGuard, NotMaintenanceGuard, NotStagingGuard, PendingGuard, StagingGuard, PendingEmailGuard, BenefitResearchGuard} from '@fengbrasil/ngx-fengst-auth';
import { MaintenanceComponent, PageNotFoundComponent, InternalServerErrorComponent, LogoutComponent, TermsComponent, RecoveryPasswordComponent, StagingComponent, ScailingMessageComponent, PrivacyComponent, DiscountValidatorComponent } from '@fengbrasil/ngx-fengst-layout';
import { LandingPartnerAppGuard } from '@fengbrasil/ngx-fengst-landing';

import { FlatvComponent } from './flatv/flatv.component';
import { PrivacyDataComponent } from './privacy-data/privacy-data.component';
import { CensusAuthGuard, RegisterConfirmGuard } from '@fengbrasil/ngx-fengst-census';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingPreHomeModule),
    canActivate: [StagingGuard, GuestGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePrivateModule),
    canActivate: [StagingGuard, AuthGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  {
    path: 'home/:page',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'sso',
    component: MsalRedirectComponent
  },
  {
    path: 'minha-conta/meu-plano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PlanModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  {
    path: 'minha-conta/meu-perfil',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ProfileModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  {
    path: 'minha-conta/pagamentos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PaymentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  // {
  //   path: 'minha-conta/dependentes',
  //   loadChildren: () =>
  //     import('@fengbrasil/ngx-fengst-account').then((m) => m.DependentsModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  // },
  {
    path: 'minha-conta/sorteios',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.RafflesModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  {
    path: 'minha-conta/lista-de-convidado',
    loadChildren: () => import('@fengbrasil/ngx-fengst-account').then(m => m.GuestListModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, BenefitResearchGuard]
  },
  {
    path: 'registrar-convidado',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterGuestModule),
  },
  {
    path: 'minha-conta/biometria',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.BiometryModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'carrinho',
    redirectTo: 'checkout/register',
    pathMatch: 'full'
  },
  {
    path: 'ingressos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-events').then((m) => m.EventsMainModule),
      canActivate: [StagingGuard, MaintenanceGuard, BenefitResearchGuard],
  },
  {
    path: 'checkout/register',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterModule),
    canActivate: [StagingGuard, MaintenanceGuard, GuestGuard, BenefitResearchGuard],
  },
  {
    path: 'checkout/validate',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ClearSaleModule),
    canActivate: [StagingGuard, MaintenanceGuard, BenefitResearchGuard],
  },
  {
    path: 'checkout/success',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SuccessModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard]
  },
  {
    path: 'checkout/subscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard]
  },
  {
    path: 'checkout/resubscribe/:idplano/:idcontrato',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResubscribeContractModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/upgrade/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.UpgradeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard]
  },
  {
    path: 'checkout/address',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard]
  },
  {
    path: 'checkout/cart/:type/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard]
  },
  {
    path: 'checkout/cart',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'pesquisa-beneficios',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.BenefitResearchModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'processando-pagamento',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then((m) => m.ProcessingPaymentModule),
      canActivate: [StagingGuard, MaintenanceGuard, AuthGuard]
  },
  {
    path: 'planos',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.PlansModule),
    canActivate: [StagingGuard, MaintenanceGuard, BenefitResearchGuard]
  },
  {
    path: 'login-app',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.LoginAppModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'sso',
    component: MsalRedirectComponent
  },
  {
    path: 'ingressos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-events').then((m) => m.EventsMainModule),
      canActivate: [MaintenanceGuard, BenefitResearchGuard],
  },
  {
    path: 'canal',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ChannelModule),
      canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard]
  },
  {
    path: 'conteudo-exclusivo',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-exclusive-content').then((m) => m.MainModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  {
    path: 'parceiros',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-partner').then((m) => m.PartnerModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  {
    path: 'consulta-de-socio',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-partner').then((m) => m.AssociateModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  {
    path: 'experiencias',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.V2AboutModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'experiencias/catalogo',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.V2CatalogModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  {
    path: 'experiencias/como-pontuar',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.V2HowModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'experiencias/extrato',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.V2PointsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  {
    path: 'experiencias/resgatados',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.V2RescuesModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, BenefitResearchGuard],
  },
  {
    path: 'pendente',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-auth').then((m) => m.PendingModule),
    canActivate: [StagingGuard, AuthGuard],
  },
  {
    path: 'validar-cadastro/:id',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ValidateAccountModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'transparencia',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-xray').then((m) => m.XrayModule),
      canActivate: [StagingGuard, MaintenanceGuard, BenefitResearchGuard],
  },
  {
    path: 'atendimento',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-contact').then((m) => m.ContactModule),
    canActivate: [StagingGuard, MaintenanceGuard, BenefitResearchGuard],
  },
  {
    path: 'fla-id',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingMainModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'fla-premium-club',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingPremiumClubModule),
    canActivate: [StagingGuard, GuestGuard],
  },
  {
    path: 'flapremiumclub',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingPremiumClubModule),
    canActivate: [StagingGuard, GuestGuard],
  },
  {
    path: 'censo',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-census').then((m) => m.AuthModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'censo/cadastro',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-census').then((m) => m.RegisterModule),
      canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'censo/confirmacao-cadastro',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-census').then((m) => m.RegisterConfirmModule),
      canActivate: [StagingGuard, MaintenanceGuard, CensusAuthGuard, RegisterConfirmGuard],
  },
  {
    path: 'censo/pesquisa',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-census').then((m) => m.ResearchModule),
      canActivate: [StagingGuard, MaintenanceGuard, CensusAuthGuard, RegisterConfirmGuard],
  },
  {
    path: 'zedelivery',
    loadChildren: () =>
    import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingPartnerRegisterModule),
      canActivate: [StagingGuard, MaintenanceGuard, LandingPartnerAppGuard],
  },
  {
    path: 'zedelivery/feedback',
    loadChildren: () =>
    import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingPartnerFeedbackModule),
      canActivate: [StagingGuard, MaintenanceGuard, LandingPartnerAppGuard],
  },
  {
    path: 'termos-de-uso',
    component: TermsComponent,
    canActivate: [StagingGuard, MaintenanceGuard, BenefitResearchGuard],
  },
  {
    path: 'aviso-de-privacidade',
    component: PrivacyComponent,
    canActivate: [StagingGuard, MaintenanceGuard, BenefitResearchGuard],
  },
  {
    path: 'privacidade',
    component: PrivacyDataComponent,
    canActivate: [StagingGuard, MaintenanceGuard, BenefitResearchGuard],
  },
  {
    path: 'flatv',
    component: FlatvComponent,
    canActivate: [StagingGuard, MaintenanceGuard, BenefitResearchGuard],
  },
  {
    path: 'sair',
    component: LogoutComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'WE',
    component: MaintenanceComponent,
    resolve: {
      url: 'externalUrlRedirectResolver',
    },
    data: {
      externalUrl: 'https://dev.novonrn.feng.rocks/WE',
    },
  },
  {
    path: 'alta-demanda',
    component: ScailingMessageComponent
  },
  {
    path: '500',
    component: InternalServerErrorComponent
  },
  {
    path: 'manutencao',
    component: MaintenanceComponent,
    canActivate: [NotMaintenanceGuard],
  },
  {
    path: 'consultar-socio',
    component: DiscountValidatorComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'staging',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'staging/login',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'recuperar-senha/:id',
    component: RecoveryPasswordComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
