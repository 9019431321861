import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const fengEnv = {
  production: false,
  maintenance: false,
  apiURL: 'https://api.dev.novonrn.feng.rocks/',
  // apiURL: 'http://localhost:3333/',
  weURL: 'https://admin.stg.nrnoficial.com.br/',
  recaptcha: {
    key: '6Lc51Q0fAAAAADLDKx_UV2AguRy1C6LNim4MlUJZ'
  },
  auth: {
    new: true,
    social: [
    ],
    cognito: {
      enable: false
    },
    adb2c: {
      enable: true,
      hybrid: true,
      name: 'Fla-ID',
      image: 'https://images.mengo.com.br/prod/assets/images/logoFlaID.png',
      b2cPolicies: {
        names: {
            signUpSignIn: 'B2C_1_AccountCreation',
            resetPassword: 'B2C_1_PassReset',
            editProfile: 'B2C_1_EditProfile',
            firstAccess: 'B2C_1_FirstAccess',
            signUpOnly: 'B2C_1_SignUpOnly',
        },
        authorities: {
            signUpSignIn: {
                authority: 'https://flaid.flamengo.com.br/crfb2c.onmicrosoft.com/B2C_1_AccountCreation',
            },
            resetPassword: {
                authority: 'https://flaid.flamengo.com.br/crfb2c.onmicrosoft.com/B2C_1_PassReset',
            },
            editProfile: {
                authority: 'https://flaid.flamengo.com.br/crfb2c.onmicrosoft.com/B2C_1_EditProfile',
            },
            firstAccess: {
                authority: 'https://flaid.flamengo.com.br/crfb2c.onmicrosoft.com/B2C_1_FirstAccess',
            },
            signUpOnly: {
                authority: 'https://flaid.flamengo.com.br/crfb2c.onmicrosoft.com/B2C_1_SignUpOnly',
            },
            changePassword: {
                authority: 'https://flaid.flamengo.com.br/crfb2c.onmicrosoft.com/B2C_1_PassReset',
            },
        },
        authorityDomain: 'flaid.flamengo.com.br',
      },
      msalConfig: {
        auth: {
            clientId: '7ac1293e-d424-4d8f-82fb-2bac1a39b722', // This is the ONLY mandatory field that you need to supply.
            authority: 'https://flaid.flamengo.com.br/crfb2c.onmicrosoft.com/B2C_1_AccountCreation', // Defaults to "https://login.microsoftonline.com/common"
            knownAuthorities: ['flaid.flamengo.com.br'], // Mark your B2C tenant's domain as trusted.
            redirectUri: '/sso', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
            postLogoutRedirectUri: '/', // Points to window.location.origin by default.
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
        },
        system: {
            /**
             * Below you can configure MSAL.js logs. For more information, visit:
             * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
             */
            loggerOptions: {
                // loggerCallback(logLevel: LogLevel, message: string) {
                //     console.log(message);
                // },
                // logLevel: LogLevel.Verbose,
                // piiLoggingEnabled: false
            }
        }
      }
    }
  },
  ubots: {
    naosocio: 'https://ubots-webchat.s3.amazonaws.com/hlg/config/Flamengo-960ffeb7-bd01-431a-b995-8bcc5e24eab7.json',
    socio: 'https://ubots-webchat.s3.amazonaws.com/hlg/config/Flamengo-e1cc6142-ab1d-40fc-91d5-acb7a7d3b5e3.json'
  }
};