import { AfterViewInit, Component, OnInit } from '@angular/core';

import * as L from 'leaflet';

@Component({
  selector: 'app-fan-map',
  templateUrl: './fan-map.component.html',
  styleUrls: ['./fan-map.component.scss']
})
export class FanMapComponent implements OnInit, AfterViewInit {

  private map: any;
  private maxSTValue: number = 500;

  private initMap(): void {
    this.map = L.map('map', {
      center: [ 
        -22.906767671903335, -43.161836935933806
      ],
      zoom: 10
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initMap();
    
    const iconRetinaUrl = 'assets/marker-icon-2x.png';
    const iconUrl = 'assets/marker-icon.png';
    const shadowUrl = 'assets/marker-shadow.png';
    const iconDefault = L.icon({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41]
    });
    L.Marker.prototype.options.icon = iconDefault;
    
    this.addMarker({
      cordx: -43.161836935933806,
      cordy: -22.906767671903335,
      size: 100,
      properties: {
        name: 'Rio de Janeiro'
      }
    })
  }

  addMarker(mark: any) {
    const lon = mark.cordx;
    const lat = mark.cordy;
    const marker = L.circleMarker([lat, lon], { radius: this.scaledRadius(mark.size, this.maxSTValue) });
    
    marker.bindPopup(this.makeCapitalPopup(mark));
    
    marker.addTo(this.map);
  }

  scaledRadius(val: number, maxVal: number): number {
    return 20 * (val / maxVal);
  }
  
  makeCapitalPopup(data: any): string {
    return `` +
      `<div>${ data.properties.name }</div>` +
      `<div>${ data.size } Sócios</div>`
  }

}
