<div class="flatv">

    <div class="flatv__block">
        <h1>VOCÊ SERÁ REDIRECIONADO PARA O</h1>
        <img src="https://images.mengo.com.br/prod/assets/images/flatvlogo.png" alt="Logo FlaTV+">
        <p>
            CASO NÃO SEJA, <a href="https://flatvmais.com.br/" target="_blank">CLIQUE AQUI</a>.
        </p>
    </div>

</div>