export const fengSettings = {
  name: 'stflamengo',
  version: '0.0.1',
  hmr: true,
  lightVersion: false,
  mockedCounter: 0,
  projectName: 'Nação',
  clientName: 'Clube de Regatas do Flamengo',
  clientId: 'CNPJ 33.649.575/0001-99',
  clientPhone: '',
  clientEmail: 'faleconosco.nacao@flamengo.com.br',
  clientAddress: 'Av. Borges de Medeiros, 997 - Gávea | Rio de Janeiro - RJ, 22430-041',
  cache: {
    strategy: 'explicit',
    localStorageKey: 'fengHttpCache',
    ttl: 60000 // 1min (60000) - 5min (300000) - 30min (1800000) - 30min (1800000) - 1h (3600000) - 2h (7200000) - 6h (21600000) - 12h (43200000) - 24h (86400000)
  },
  auth: {
    enableSt: true,
    enableResale: false,
    enableCaptcha: true,
    verifyCPF: true,
    verifyEmail: true,
    keepConnected: true,
    enableAuthModalRedirect: true,
    loginForm: {
      title: 'Para primeiro acesso ao novo sistema, clique abaixo em "Gerar nova senha" para receber no seu e-mail cadastrado',
      text: 'Email/CPF',
      forgotPass: 'Gerar nova senha',
      forgotPassText: 'Preencha com o seu e-mail cadastrado no Nação e enviaremos um link de recuperação para você. O e-mail só será enviado caso o cadastro seja encontrado.',
      forgotEmailText: 'Caso você tenha esquecido o seu e-mail, entre em contato com o atendimento <a href="/atendimento">clicando aqui</a>.'
    },
    firstAccess: {
      enable: true,
      migrationLogic: false,
      ask: {
        password: false,
        terms: true,
        missingData: false
      },
      patterns: {
        enabled: true,
        findAll: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]).{10,14}$/,
        minimumCharacters: 8,
        atLeastOneNumber: /\d/,
        atLeastOneUpperCase: /[A-Z]/,
        atLeastOneLowerCase: /[a-z]/,
        atLeastOneSpecialCharacter: /[!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
        validated: {
          minimumCharacters: 'Mínimo de 8 caracteres',
          atLeastOneNumber: 'Pelo menos um número',
          atLeastOneUpperCase: 'Pelo menos uma letra maiúscula',
          atLeastOneLowerCase: 'Pelo menos uma letra minúscula',
          atLeastOneSpecialCharacter: 'Pelo menos um caracter especial',
        }
      },
    },
    social: [
      { name: 'google', enabled: false },
      { name: 'facebook', enabled: false }
    ],
    register: {
      enableSt: true,
      showTerms: true,
      enableClearSale: true,
      nameMaxLength: 60,
      secondaryColorError: true,
      enableDdi: true,
      fields: {
        genre: true,
        clubId: true
      }
    },
    clearSale: {
      // https://github.com/code-farmz/ng-otp-input
      // https://www.npmjs.com/package/ng-otp-input
      enable: true,
      successMessage: 'Cadastro validado.',
      OTPConfig: {
        allowNumbersOnly: true,
        length: 6,
        isPasswordInput: false,
        disableAutoFocus: false,
        placeholder: "",
        inputStyles: {
          width: "40px",
          height: "50px",
        },
        resendTokenWaitTimeInSeconds: 60,
      },
      loadingStatus: {
        score: 'Estamos validando algumas informações.<br />Por favor, não feche e não recarregue a página.',
        backgroundCheck: 'Para sua segurança estamos validando seus dados.<br />Por favor, não feche e não recarregue a página.',
        getSms: 'Solicitando token para confirmar número de telefone.<br />Por favor, não feche e não recarregue a página.',
        sms: 'Validando o token.<br />Por favor, não feche e não recarregue a página.',
      }
    },
    staging: {
      enable: false,
      title: 'SEJA BEM-VINDO!',
      subtitle: 'STG Nação',
      description: `
        <p>
          Bloqueio de navegação para ambiente de staging <strong>em fase de teste</strong>.
        </p>
        <p>
          Para liberar navegação, adicione <strong>/login</strong> no final da URL desta página
          e efetue o login com um <strong>usuário administrativo</strong> com
          <strong>perfil 'Administrador', 'Desenvolvedor' ou 'Staging'.
        </p>
        <p>
          <strong>Após validação da funcionalidade, esta mensagem será removida.</strong>
        </p>
      `,
    },
  },
  payment: {
    creditCard : {
      maxAllowed: 3,
      allowedTypes: [
        { type: 'visa', regex: new RegExp('^4') },
        { type: 'mastercard', regex: new RegExp('^5[1-5]') },
        { type: 'american-express', regex: new RegExp('^3[47]') },
        { type: 'diners', regex: new RegExp('^30[0-5]') },
        { type: 'jcb', regex: new RegExp('^35(2[89]|[3-8][0-9])') },
        { type: 'visa-electron', regex: new RegExp('^(4026|417500|4508|4844|491(3|7))') },
        { type: 'maestro',regex: new RegExp('^(5000|5018|5020|5038|6304|6759|676[1-3])') },
        { type: 'discover',regex: new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)') },
        { type: 'elo',regex: new RegExp('^(401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636368|655000|655001|651652|651653|651654|650485|650486|650487|650488|506699|5067[0-6][0-9]|50677[0-8]|509\d{3})') },
        { type: 'hipercard',regex: new RegExp('^(606282\d{10}(\d{3})?|3841\d{15})') },
      ],
      enableCaptcha: true,
      enableDelete: true
    },
    installments: {
      enableInstallments: false,
      multiplePayment: {
        creditCardAllowed: true,
        pixAllowed: true,
        bankSlipAllowed: true,
      },
      showCreditCardPaymentMethod: [
        '100003',
        'a4cd274714f182346e863a9c857c442b',
        '02c60168e4be76ca7cf675e17e8c5d21',
        'bb08acf31e0287e944f20ef943473ec4',
      ],
      subdivision: {
        creditCard: false,
      }
    },
    tresDS: {
      enable: false
    }
  },
  loading: {
    global: 'ring', // default | ring | orbital | pulse
    local: 'bars' // default | bars | bullets | progress
  },
  homeSettings: {
    enableBg: false,
    scroll: {
      bannerUnlogged: true,
      callToAction: {
        enable: true,
        template: 13,
        titleImage: ''
      },
      hero: false,
      tickets: {
        enable: false,
        alternativeEnable: false,
        template: 2
      },
      tabs: {
        enable: true,
        template: 12
      },
      plans: {
        enable: true,
        enableColorTheme: true

      },
      specialPlans: {
        enabled: true
      },
      exclContent: false,
      testimony: {
        enable: false,
        template: 1
      },
      callToActionFooter: false,
      callToActionMatchday:{
        enable: true,
        matchdayLogo: 'https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/logo-flapremiumclub2.svg',
        text: `Um clube exclusivo para estar ao lado do rubro-negro`,
        redirectTo: 'https://dev.novonrn.feng.rocks/fla-premium-club' //TODO: substituir por https://mengo.com.br/fla-premium-club no deploy!
      }
    },
    banner: {
      template: 1,
      className: 'NACAO'
    },
    cards: {
      enable: true,
      contentBox: true,
      experiences: true,
      plans: true,
      ticketCards: true,
      tickets: true,
      enableColorTheme: true
    },
    header: {
      legacyMsg: ``,
      templateHeader: 2,
      activeNewPlan: false,
      voucherMessage: 'Parabéns, você ganhou um <strong>$MENGO</strong><br /><a href="https://link.socios.com/l/?link=https%3A%2F%2Fwww.socios.com%2Fmarketplace&apn=com.socios&ibi=com.socios&isi=1464868277&ofl=https://www.socios.com&utm_source=Newsletter4&utm_medium=Emailnewsletter&utm_campaign=FLA_FT_EMKT" target="_blank">Baixe o app</a> da Socios.com e ative o fan token ',
    },
    enableContractStatusWarning: true
  },
  myAccount: {
    home: {
			showBannerNewLink: true
		},
    biometry: {
      clubName: 'Flamengo'
    },
    menu: {
      plan: true,
      profile: true,
      payment: true,
      dependents: false,
      guest: true,
      biometry: true
    },
    enableColorTheme: true,
    plan: {
      enableColorTheme: true,
      ticketCard: {
        image: '',
        showImage: true,
        enableRating: true,
        enableStarRating: false,
        ratingDescription: 'PRIORIDADE',
      },
      enableTicketCardHistory: true,
      enableActivePreviousTicketCard: true,
    },
    profile: {
      complementary: {
        enabled: true,
        fields: {
          nationality: true,
          rg: true,
          civilStatus: true,
          education: true,
          residentialPhone: true,
          shirtSize: true,
          shortSize: true,
          shoeSize: true,
          income: false,
          interest: false,
          matchFrequency: true,
          hasChildren: true,
          childrenNumber: true,
          profession: false,
          company: false,
          interestWorkField: false,
          club: false
        },
        options: {
          shirtSize: [
            { value: 'P', label: 'P'},
            { value: 'M', label: 'M'},
            { value: 'G', label: 'G'},
            { value: 'GG', label: 'GG'},
            { value: '3G', label: '3G'},
            { value: '4G', label: '4G'},
            { value: '7G', label: '7G'},
          ]
        }
      },
      showMatricula: true,
      disableEmailEdit: true,
      disableCellphoneEdit: true,
    },
    address: {
      addressNumberModifier: true,
      addressReference: true,
      addressReferenceLength: 20,
    },
    socialNetworks: {
      enabled: true
    },
    ticketCards: {
      ticketCardTemplate: 1,
      tracking: {
        enabled: true,
        company: 'flash', // Available: 'flash'
        activateWarningMessage: `Seu cartão-ingresso foi entregue mas ainda não foi ativado.
          Por favor, acesse <strong>Meu Plano</strong> ou  <a href="/minha-conta/meu-plano"
          target="_blank">Clique aqui</a> e ative seu cartão-ingresso.
        `,
      },
      textWarn: {
        enableForm: true,
        textInfo: `<strong>ATENÇÃO</strong> - Ative sua carteirinha clicando no botão abaixo <strong>apenas</strong> se já tiver com ela em mãos para usufruir dos benefícios`
      },
      enableCharOnValidation: true
    },
    optin: {
      enabled: false
    },
    packageBalance: {
      enabled: true
    },
    guestList: {
      name: 'Lista de Favoritos',
      guestLimit: 5,
      guestChangeMonths: 2,
      guestLimitPerPlan: {
        diamond: 10,
        platinum: 10,
        gold: 5
      }
    },
    guestRegister: {
      title: 'CADASTRO DE CONVIDADOS',
      subtitle: 'PREENCHA OS CAMPOS ABAIXO COM OS DADOS COMPLETOS DO SEU CONVIDADO PARA PROSSEGUIR COM O CADASTRO',
    }
  },
  partnersSettings: {
    enableMoreInfo: false,
    enableKnowMore: true,
    enableColorTheme: true,
    enablePartiu: false,
    becomePartner: {
      enable: true,
      address: {
        numberMaxLength: 4,
        enableLimitCharacter: true,
      },
      discount: {
        increment: 5,
        type: "percentage", // Available: percentage [default] | amount
      },
      terms: {
        pageTitle: null,
        contentKeywords: ['contrato', 'parceria']
      }
    }
  },
  experiencesSettings: {
    version: 'v2',
    menu: {
      catalog: true,
      howToScore: true,
      points: true,
      rescued: true
    },
    enableSocioClub: false,
    rescues: {
      cardTemplate: 1,
      tracking: {
        enabled: true,
        company: 'flash' // Available: 'flash'
      }
    },
    catalog: {
      enableBanner: true,
      texts : {
        bannerDescription: 'O UNIVERSO RUBRO-NEGRO DE EXPERIÊNCIAS'
      },
      buttons: {
        event: 'PARTICIPE'
      }
    },
    points: {
      enabledExperiences: false,
      enabledLoyalty: false,
      enabledFilterCategories: false,
      cardTemplate: 'fngst-1',
    },
    embassies: {
      enableFilter: true,
    },
    queueRetryLimit: 12,
    experienceV2: {
      enabled: true,
      status: {
        opened: true,
        futures: true,
        closed: true,
        nextEvents: false,
      },
      showExternal: true,
      submenuItems: [
        { label: 'O que é', route: '/experiencias/o-que-e' },
        { label: 'Catálogo', route: '/experiencias/catalogo' },
        { label: 'Resgatados', route: '/experiencias/resgatados' },
        { label: 'Extrato', route: '/experiencias/extrato' },
        { label: 'Como pontuar', route: '/experiencias/como-pontuar' },
      ],
      catalog: {
        ctaText: 'Não perca tempo e participe de experiências rubro-negras.',
        info: {
          image: 'https://images.mengo.com.br/prod/conteudo/we/Visita+CT.jpg',
          tag: 'EXPERIÊNCIA',
          title: 'Visita ao CT',
          description: 'Resgate essa experiência para conhecer o Ninho do Urubu!',
          openingDetails: 'Abertura da experiência: <strong>16/06/24</strong>'
        },
      },
      landing: {
        aboutScroll: {
          title: 'Seja bem-vindo(a) ao Fla-Experience!',
          description: 'Um universo rubro-negro de experiências exclusivas e inesquecíveis.',
        },
        videoScroll: {
          title: 'CONHEÇA O NOSSO UNIVERSO',
          description: 'Resgate experiências exclusivas do universo do Mengão e aproveite a oportunidade de viver o Flamengo ainda mais de perto!',
          videos: [
            {
              title: 'Matchday',
              subtitle: 'Resgate esta experiência e viva um Matchday do Mengão no Maraca.',
              description: 'No Matchday você vive um dia de jogo do Mengão como nunca viveu antes. Conheça os bastidores do Maracanã, sala de imprensa, vestiário com os Mantos que os nossos craque usarão no jogo e, também, o histórico gramado do Maracanã. Resgate a experiência do Matchday e viva um dia inesquecível com o Flamengo.',
              videoURL: 'https://images.mengo.com.br/prod/assets/images/flaexperience/Matchday.mp4',
            },
            {
              title: 'Visita ao CT',
              subtitle: 'Resgate esta experiência para conhecer os módulos de treinamento do futebol.',
              description: 'Visitando o CT George Helal você pode conhecer toda a infraestrutura do centro de treinamento do Flamengo. Campos, academia, espaços de descanso e lazer que só os jogadores profissionais e das categorias de base têm acesso.',
              videoURL: 'https://images.mengo.com.br/prod/assets/images/flaexperience/CT.mp4',
            },
            {
              title: 'Mascotinhos',
              subtitle: 'Seu Pequeno Rubro-Negro vivendo a experiência inesquecível de entrar em campo com os craques do Mengão.',
              description: 'Entrar em campo com os craques do nosso time é uma experiência memorável. Seu pequeno rubro-negro pode viver esse dia inesquecível. Resgate e garanta esse dia especial.',
              videoURL: 'https://images.mengo.com.br/prod/assets/images/flaexperience/Mascotinhos.mp4',
            }
          ],
        },
        catalogScroll: {
          title: 'NOSSAS EXPERIÊNCIAS',
          itens: [
            {
              title: 'Apresentação de Atleta',
              subtitle: 'Resgate esta experiência e participe da coletiva de apresentação do novo craque rubro-negro.',
              description: 'Resgate esta experiência e participe da coletiva de apresentação do novo craque rubro-negro.',
              thumbnail: 'https://images.mengo.com.br/prod/assets/images/flaexperience/img-catalogo-01.png',
            },
            {
              title: 'Matchday',
              subtitle: 'Resgate esta experiência e viva um Matchday do Mengão, no Maraca, visitando a sala de imprensa, vestiário...',
              description: 'Resgate esta experiência e viva um Matchday do Mengão, no Maraca, visitando a sala de imprensa, vestiário, gramado e recebendo os nossos craques.',
              thumbnail: 'https://images.mengo.com.br/prod/assets/images/flaexperience/img-catalogo-02.png'
            },
            {
              title: 'Visita CT Ninho do Urubu',
              subtitle: 'Resgate esta experiência para conhecer os módulos de treinamento do futebol profissional...',
              description: 'Resgate esta experiência para conhecer os módulos de treinamento do futebol profissional e das categorias de base do Flamengo. Fique por dentro de toda a estrutura de preparação dos nossos atletas.',
              thumbnail: 'https://images.mengo.com.br/prod/assets/images/flaexperience/img-catalogo-03.png'
            },
            {
              title: 'Ingressos',
              subtitle: 'Resgate um par de ingressos para o setor mais próximo do gramado do Maracanã. Não perca nenhum lance.',
              description: 'Resgate um par de ingressos para o setor mais próximo do gramado do Maracanã. Não perca nenhum lance.',
              thumbnail: 'https://images.mengo.com.br/prod/assets/images/flaexperience/img-catalogo-04.png'
            },
            {
              title: 'Mascotinhos',
              subtitle: 'Seu Pequeno Rubro-Negro vivendo a experiência inesquecível de entrar em campo com os craques do Mengão.',
              description: 'Entrar em campo com os craques do nosso time é uma experiência memorável. Seu pequeno rubro-negro pode viver esse dia inesquecível. Resgate e garanta esse dia especial.',
              thumbnail: 'https://images.mengo.com.br/prod/assets/images/flaexperience/img-catalogo-05.png'
            }
          ],
        },
        howScroll: [
          {
            icon: 'fengi-icon-estadio',
            title: 'Como participar',
            description: 'Ao aderir a um plano, todos os sócios-torcedores estarão, automaticamente, aptos a participarem. Não é necessário nenhum cadastro extra!',
          },
          {
            icon: 'fengi-experience-medal',
            title: 'Acumule pontos',
            description: 'Você ganha pontos pela adimplência, compra de ingressos, renovação e upgrade do plano. Quanto maior o seu plano, mais pontos você ganha!',
          },
          {
            icon: 'fengi-icon-conteudo-prioridade',
            title: 'Troque seus pontos',
            description: 'Seus pontos podem ser trocados por experiências rubro-negras incríveis, para que você viva o Flamengo mais de perto. Confira o catálogo completo!',
          },
          {
            icon: 'fengi-exchange-points',
            title: 'Como trocar meus pontos',
            description: 'Acesse o nosso catálogo completo de experiências para verificar a disponibilidade e a quantidade de pontos necessários para efetuar o resgate da experiência que você deseja viver com o Flamengo.',
          }
        ]
      }
    }
  },
  faqSettings: {
    faqTemplate: 6,
    mockFaq: false,
    enableColorTheme: true,
    contacts: {
      chat: {
        active: true,
        availability: 'de segunda a sexta, das 09h às 18h, e aos sábados, das 09h às 12h',
      },
      email: {
        active: true
      }
    }
  },
  headerSettings: {
    headerTemplate: 8,
    floatingLogin: false,
    enableCounter: false,
    enableMobileMenu: true,
    nav: [
      { title: 'HOME', routerLink: '/home', routerLinkActiveOptions:  { exact: true } },
      { title: 'MINHA CONTA', routerLink: '/minha-conta/meu-plano', routerLinkActiveOptions: { exact: false } },
      { title: 'PLANOS', routerLink: '/planos', routerLinkActiveOptions: { exact: true } },
      { title: 'PARCEIROS', routerLink: '/parceiros', routerLinkActiveOptions: { exact: true } },
      { title: 'FLA-EXPERIENCE', routerLink: '/experiencias/catalogo', routerLinkActiveOptions: { exact: false } },
      // { title: 'CONT. EXCLUSIVO', routerLink: '/conteudo-exclusivo', routerLinkActiveOptions: { exact: true } },
      { title: 'FLATV+', routerLink: '/canal', routerLinkActiveOptions: { exact: true } },
      { title: 'FLA-CHIP', routerLink: `https://fla-chip.com.br/`, routerLinkActiveOptions: { exact: true }, click:'externalurl' },
      { title: 'INGRESSOS', routerLink: '/ingressos', routerLinkActiveOptions: { exact: true } },
      // { title: 'TRANSPARÊNCIA', routerLink: '/transparencia', routerLinkActiveOptions: { exact: true } },
      { title: 'FAQ', routerLink: '/atendimento', routerLinkActiveOptions: { exact: true } },
      { title: 'PRIVACIDADE', routerLink: '/privacidade', routerLinkActiveOptions: { exact: true } },
    ],
    hidePoints: false
  },
  footerSettings: {
    footerTemplate: 4,
    copyrightTemplate: 2,
    social: [
      { icon: 'twitter', href: 'https://twitter.com/NacaoCRF' },
      { icon: 'instagram', href: 'https://www.instagram.com/nacaocrf/?hl=pt-br' },
      // { icon: 'youtube', href: 'https://www.youtube.com' },
      { icon: 'facebook', href: 'https://www.facebook.com/NacaoCRF' },
      { icon: 'whatsapp', href: 'https://wa.me/552135121212' }
    ],
    nav: [
      { title: 'HOME', routerLink: '/', click: '', clickParam: '', if: 'notHome' },
      { title: 'PLANOS', routerLink: '/planos', click: '', clickParam: '', if: '' },
      { title: 'INGRESSOS', routerLink: '/ingressos', click: '', clickParam: '', if: '' },
      { title: 'PARCEIROS', routerLink: '/parceiros', click: '', clickParam: '', if: '' },
      { title: 'FLA-EXPERIENCE', routerLink: '/experiencias', click: '', clickParam: '', if: '' },
      // { title: 'CONTEÚDO EXCLUSIVO', routerLink: '/conteudo-exclusivo', routerLinkActiveOptions: { exact: true } },
      { title: 'FLATV+', routerLink: '/canal', click: '', clickParam: '', if: ''  },
      // { title: 'TRANSPARÊNCIA', routerLink: '/transparencia', click: '', clickParam: '', if: '' },
      { title: 'FAQ', routerLink: '/atendimento', click: '', clickParam: '', if: '' },
      { title: 'PRIVACIDADE', routerLink: '/privacidade', click: '', clickParam: '', if: '' },
      { title: 'SEJA SÓCIO-TORCEDOR', routerLink: '/checkout/cart', click: '', clickParam: '', if: '' },
      { title: 'LOGOUT', routerLink: '', click: 'logout', clickParam: '', if: 'isAuth' },
    ],
    enableTerms: true
  },
  plans: {
    template: 6,
    cardPlaceholder: "https://ngx-feng.s3.sa-east-1.amazonaws.com/images/loading/img-placeholder.png",
    enableDependents: false,
    subscribeText: 'SEJA SÓCIO-TORCEDOR',
    registrationInClubeText: `<br>Adesão do plano a ser realizada exclusivamente na sede do Clube.<br>Endereço: Av. Borges de Medeiros, 997 - Térreo (ao lado da Ouvidoria).<br><br>`,
    registrationInClube: true,
    //TODO - Vir da API
    tempUnavaliablePlans: [
    ],
    tempUnavailableRenewPlans: [
    ],
    tempUnavailableUpgradePlans: [
      // 'NAÇÃO DIAMANTE',
      // 'NAÇÃO PLATINA',
      // 'NAÇÃO OURO',
      // 'NAÇÃO PRATA'
    ],
    enableAsterisk: true,
    hasInformativeModal: [
      // '6a405c75d8db804dd1d3251c07e4f1a5', //PRATA
      // 'a938f3614ed7b475fadee0d27479d42a', //OURO
      // '4a09f9800db354725e9899efdbde1101', //PLATINA
      // '568a84b066b0c78f094f084eb006a7e7', //DIAMANTE
    ],
    informationModalContent: {
      title: '',
      description: ``,
      address: ``,
      descriptionComplement: ``,
    }
  },
  specialPlan: {
    template: 2,
  },
  xRay: {
    featuredTemplate: 4,
    financialTemplate: 6,
    enableCounter: false,
    enableTabBackgroundColor: true,
    enableStates: true,
    enableCities: true,
    enableDistricts: true,
    enablePlans: true,
    enablePaymentMethod: true,
    enableAge: true,
    enableSex: true,
    enableXRay: true,
    enableXRayActionBackgroundColor: true,
    enableFinancial: false,
    enableTransparency: false,
    tranparencyMonthsNameType: 'fullName', // fullName | shortName,
  },
  embassies: {
    template: 2
  },
  checkout: {
    defaultDuration: '12',
    maxGuests: false,
    setQuantityGuest: true,
    hasGuestsRulesDiscount: {
      enable: true,
      guests_qty: 3
    },
    discountActiveEnrollment: true,
    showSimulationTotalPlanValue: true,
    showPaymentMethods: true,
    comingSoonPaymentSlip: false,
    addressLogradouroLength: 40,
    addressComplementLength: 20,
    defaultPaymentMethod: 'PIX',
    discountEnrollmentMessage: {
      inactiveEnrollment: {
        title: 'MATRÍCULA NÃO ATIVA',
        message: 'Você precisa regularizar sua matrícula no clube para utilizar o desconto de sócio clube no Nação Sócio Torcedor',
        buttonText: 'PROSSEGUIR SEM O DESCONTO'
      },
      errorExternalAPI: {
        title: 'ATENÇÃO',
        message: 'Houve um erro ao consultar o status da sua matrícula. Ao prosseguir, o desconto de sócio clube não será aplicado',
        buttonText: 'PROSSEGUIR SEM O DESCONTO'
      }
    },
    modalSixPeriodicity: {
      enabled: false,
      title: 'ATENÇÃO',
      message: `
        Os planos com duração semestral não possuem o benefício do kit de boas-vindas.
        Você receberá apenas a carteirinha. Caso deseje receber o kit, altere a periodicidade para Anual.
      `
    },
    disableSteloMessage: true,
    offState: {
      state: 'RJ',
      modalOffState: {
        enabled: false,
        title: 'ATENÇÃO',
        message: `
          Você selecionou um plano fora do estado do Rio de Janeiro, iremos te redirecionar para página de planos para selecionar o plano correto.
        `
      }
    },
    offCity: {
      city: 'Rio de Janeiro',
      districts: [],
      modalOffCity: {
        enabled: true,
        title: 'ATENÇÃO',
        message: `
          Você selecionou um plano fora do Rio de Janeiro, iremos te direcionar para a página de planos para selecionar o plano correto.
        `
      }
    },
    successPage: {
      enabled: true,
      title: 'Pagamento processado!',
      message: `Seja bem vindo ao Nação.`
    },
    boletoPage: {
      enabled: true,
      title: 'Boleto gerado com sucesso!',
      message: `Agora falta pouco! Pague o seu boleto até a data de vencimento.`
    },
    pixPage: {
      enabled: true,
      title: 'Código PIX gerado com sucesso',
      message: `Agora falta pouco! Pague a fatura gerada através do QR Code abaixo.`
    },
    paymentFailPage: {
      enabled: true,
      title: 'Ocorreu um erro ao efetuar o seu pagamento!',
      message: `Não foi possível processar o seu pagamento na forma de pagamento selecionada.`
    },
    campaign: {
      name: 'Anjos da Guarda',
      couponPrefix: 'ANJO',
      enableExPartner: false,
      exPartnerCoupon: 'VOLTEPRONACAO'
    },
    enable_quick_renew: false,
    renew: {
      defaultDuration: '12',
      lockDuration: true
    },

    enable_recurrency: false,
    enableValidator: true
  },
  events: {
    shield: "https://images.mengo.com.br/prod/assets/images/club-shield.png",
    background: "https://images.mengo.com.br/prod/assets/images/bg-events.png",
    name: "FLAMENGO",
    template: 1,
    ticketing: 'SUPERINGRESSO', // Available Options: FCARD / MBILHETE / TICKETHUB / SUPERINGRESSO
    showMoreInfo: true,
    moreInfoType: 'waves',  // Available Options: text | waves
    imageMoreInformation: 'https://ngx-feng.s3.sa-east-1.amazonaws.com/images/event-card-more-info.png',
    showMyTickets: true,
    showTicketsClose: true,
    multTicketeira: true,
    clubLinkFcard: 'FutebolCard em flamengo.futebolcard.com',
    myTicketsEvent: '0fe183d0dbd2921e097e619342cde489',
    showRating: true,
    ratingText: 'Nível',
    enableTicketCardDeliveryGuard: true,
    worthWhatPay: {
      ids: [],
      message: 'ESSE JOGO VALE O INGRESSO',
    },
    packageBalance: {
      enabled: false
    },
    integration: {
      enableTicketBalance: true
    }
  },
  terms: {
    splitted: true,
    termsURL: {
      url: null,
      route: '/termos-de-uso',
      pageTitle: null,
      contentKeywords: ['termo', 'novos', 'planos'],
      linkText: 'TERMOS DE USO'
    },
    privacyURL: {
      url: null,
      route: '/aviso-de-privacidade',
      pageTitle: 'Aviso de Privacidade',
      contentKeywords: ['politica', 'privacidade'],
      linkText: 'AVISO DE PRIVACIDADE'
    }
  },
  landing: {
    template: 5,
    promptValidation: {
      cpf: false,
      email: false,
    },
    header: {
      partner: 'https://flanacaorubronegra.s3.sa-east-1.amazonaws.com/ah/landing-090323-ze/logo-ze-g.png',
      logo: 'https://flanacaorubronegra.s3.sa-east-1.amazonaws.com/ah/landing-090323-ze/logo-st-nova-g.png',
    },
    nav: {
      registerRoute: '/zedelivery',
      feedbackRoute: '/zedelivery/feedback',
    },
    title: ``,
    description: `
      <p><strong>Sócio-torcedor</strong> do Nação receberá cupons de desconto de até R$10 para utilizar em compras de R$50 ou mais no app do Zé Delivery, onde você encontra bebida rápida, gelada e no precinho!</p>
      <p>Preencha os campos abaixo com os mesmos dados de cadastro utilizados no Nação Sócio-Torcedor.</p>
      <p>Atenção: utilize o mesmo e-mail na conta de sócio-torcedor e no app do Zé.</p>
    `,
    warning: `
      <p>O benefício será liberado direto no app do Zé Delivery em até sete dias após o cadastro do sócio-torcedor titular, ativo e adimplente.</p>
      <p>Se liga! Os cupons serão de até R$10, com validade até o fim do mês corrente, não sendo cumulativos entre si ou com outros cupons promocionais.</p>
    `,
    app: [
      '35e6cb31-6e89-4d8e-8757-21275c5c3b81',
      'd5d9ab75-451f-472e-820a-307d1b71b8b5'
    ], // zé delivery STG ('35e6cb31-6e89-4d8e-8757-21275c5c3b81') // zé delivery PRODUÇÃO ('d5d9ab75-451f-472e-820a-307d1b71b8b5')
    preHome: {
      title: 'A qualquer momento, em qualquer lugar',
      subtitle: 'Viva o Flamengo no Maracanã, ou onde você estiver',
      cards: [
        {
          logo: 'https://images.mengo.com.br/matchday/prod/assets/img/Prehome/2024/logo-flapremios.png',
          description: 'GANHE PRÊMIOS ACOMPANHANDO O MAIS QUERIDO DE ONDE VOCÊ ESTIVER',
          buttonText: 'ENTRAR NO <br class="feng-show-block-xs"> FLA-PRÊMIOS',
          redirectLink: 'https://stg.flamengo.matchday.feng.rocks',
          redirectRoute: '',
          background: 'https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/pre-home-premium.png'
        },
        {
          logo: 'https://images.mengo.com.br/matchday/prod/assets/img/Prehome/2024/logo-nacao.png',
          description: 'Você mais perto do Mengão com benefícios exclusivos',
          buttonText: '<br class="feng-show-block-xs"> SÓCIO-TORCEDOR',
          redirectLink: '',
          redirectRoute: 'home/inicio',
          background: 'https://images.mengo.com.br/matchday/prod/assets/img/Prehome/2024/img-estadio.png'
        }
      ]
    }
  },
  premiumClub: {
    cta: {
      title: 'FLA PREMIUM CLUB',
      subtitle: 'Um clube exclusivo para estar ao lado do rubro-negro'
    },
    benefitsModalContent: [
      {
        titulo: 'Concierge Exclusivo',
        descricao: `<p><strong>Concierge Exclusivo</strong></p>`
      },
      {
        titulo: 'Nação Diamante',
        descricao: `<p><strong>Nação Diamante</strong></p>`
      },
      {
        titulo: 'Experiências',
        descricao: `<p><strong>Experiências</strong></p>`,
      },
      {
        titulo: 'Welcome Kit',
        descricao: `<p><strong>Welcome Kit</strong></p>`
      }
    ],
    experiencesSettings: {
      catalogScroll: {
        itens: [
          {
            title: 'Visita CT Ninho do Urubu',
            description: 'Resgate esta experiência para conhecer os módulos de treinamento do futebol profissional e das categorias de base do Flamengo. Fique por dentro de toda a estrutura de preparação dos nossos atletas. ',
            thumbnail: 'https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/exp-img-1.png',
          },
          {
            title: 'Matchday',
            description: 'Resgate esta experiência e viva um Matchday do Mengão, no Maraca, visitando a sala de imprensa, vestiário, gramado e recebendo os nossos craques.',
            thumbnail: 'https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/exp-img-2.png'
          },
          {
            title: 'Museu Flamengo',
            description: 'Resgate esta experiência e participe da coletiva de apresentação do novo craque rubro-negro.',
            thumbnail: 'https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/exp-img-3.png'
          },
          {
            title: 'Desafio do Nação',
            description: 'Resgate um par de ingressos para o setor mais próximo do gramado do Maracanã. Não perca nenhum lance.',
            thumbnail: 'https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/exp-img-4.png'
          },
          {
            title: 'Nação te leva',
            description: 'Resgate um par de acessos ao lounge do Nação e viva uma experiência exclusiva no Maracanã.',
            thumbnail: 'https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/exp-img-5.png'
          }
        ],
      },
    },
    footer: [
      { title: 'FAQ', routerLink: '/atendimento', click: '', clickParam: '', if: '' },
      { title: 'PRIVACIDADE', routerLink: '/privacidade', click: '', clickParam: '', if: '' },
      { title: 'SEJA SÓCIO-TORCEDOR', routerLink: '/checkout/cart', click: '', clickParam: '', if: '' },
      { title: 'FLATV+', routerLink: '/canal', click: '', clickParam: '', if: ''  },
      { title: 'INGRESSOS', routerLink: '/ingressos', click: '', clickParam: '', if: '' },
    ]
  },
  census: {
    sac: {
      phone: '(21) 3512-1212',
      email: 'faleconosco.nacao@flamengo.com.br'
    },
    footer: {
      copyrightName: 'Flamengo'
    },
    showProgressBar: true,
    OTPConfig: {
        allowNumbersOnly: true,
        length: 6,
        isPasswordInput: false,
        disableAutoFocus: false,
        placeholder: "",
        inputStyles: {
            "max-width": "40px",
            "min-width": "10px",
            "height": "60px",
        },
        status: {
        loading: 'Carregando...',
        validatingToken: 'Validando token...',
        },
        benefits: true,
    },
    introMessage: {
      title: 'Participe e Faça a Diferença!',
      message: 'Bem-vindo ao nosso censo! Agradecemos sua disposição em compartilhar suas informações conosco. Sua participação é fundamental para nos ajudar a entender melhor nossa base de torcedores e oferecer experiências mais personalizadas. Obrigado por contribuir!'
    },
    errorMessages: {
      internalError: {
        title: 'Ops... Parece que ocorreu um erro inesperado.',
        message: 'Lamentamos o inconveniente. Por favor, tente novamente mais tarde. Se o problema persistir, entre em contato conosco para que possamos ajudá-lo.'
      },
    },
    registerMessage: {
      title: 'Cadastre ou atualize seus dados para continuar com a pesquisa.',
      message: 'Por favor, preencha o formulário abaixo com suas informações pessoais e de endereço. Se você já possui um cadastro conosco, pode atualizar suas informações aqui. Se este é seu primeiro acesso, preencha os campos para seguir.'
    },
    successMessage: {
      title: 'Obrigado por participar do Censo!',
      message: 'Agradecemos sinceramente por compartilhar essas informações conosco! Sua participação é fundamental para o aprimoramento contínuo do nosso clube. Obrigado pelo seu tempo e contribuição!'
    },
    confirmMessage: {
      title: 'Confirme os seus dados para continuar com a pesquisa.',
    },
  },
  hotSite: {
    header: {
      logo: 'https://images.mengo.com.br/prod/assets/images/flaid/flaid-vermelho.png',
    },
    benefits: {
      title: 'A PARTIR DE AGORA, VOCÊ TERÁ UM',
      subtitle: 'ÚNICO LOGIN PARA ACESSAR SUAS CONTAS DO MENGÃO',
      cards: [
        {
          img: 'https://images.mengo.com.br/prod/assets/images/flaid/flaid-banner.png',
          title: 'O que é o Fla-ID?',
          description: `
            O Fla-ID é a identidade do torcedor rubro-negro. Um login único para entrar e acessar todas as suas contas dos produtos e serviços do Flamengo.
          `
        },
        {
          img: 'https://images.mengo.com.br/prod/assets/images/flaid/motivo-banner.png',
          title: 'Por que fazer o Fla-ID?',
          description: `
            Com o Fla-ID você terá uma nova experiência mais segura e prática para navegar com o Mais Querido. Além, é claro, de toda a facilidade de ter apenas um login.
          `
        },
        {
          img: 'https://images.mengo.com.br/prod/assets/images/flaid/canais-banner.png',
          title: 'Quais canais do Flamengo acesso com o Fla-ID?',
          description: `
            Você pode acessar, usando o Fla-ID, sua conta do Nação Sócio-torcedor, da FLATV+ e do FLA-APP. Em breve, esse método de acesso estará disponível em mais canais do Mengão.
          `
        },
        {
          img: 'https://images.mengo.com.br/prod/assets/images/flaid/torcida-banner.png',
          title: 'Queremos te ouvir! <br>Conheça nossa pesquisa',
          description: `
            O Flamengo quer conhecer ainda mais a Maior Torcida do Mundo. Após criar seu Fla-ID - a identidade do torcedor rubro-negro - responda a pesquisa complementar e nos ajude a estar mais próximo da Nação.
          `
        },
      ]
    },

    footer: {
      shield: 'https://images.mengo.com.br/prod/assets/images/flaid/flamengo-logo.svg',
      logo: 'https://images.mengo.com.br/prod/assets/images/flaid/flaid-preto.png',
      social: [
        { icon: 'facebook_icon', href: 'https://www.facebook.com/NacaoCRF' },
        { icon: 'twitter', href: 'https://twitter.com/NacaoCRF' },
        { icon: 'youtube', href: 'https://www.youtube.com' },
        { icon: 'instagram', href: 'https://www.instagram.com/nacaocrf/?hl=pt-br' },
      ],
    },
  },
}
