import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flatv',
  templateUrl: './flatv.component.html',
  styleUrls: ['./flatv.component.scss']
})
export class FlatvComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      window.open('https://flatvmais.com.br/', '_blank');
    }, 6000);
  }

}
