import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import {
  OpenTelemetryInterceptorModule,
  OtelColExporterModule,
  CompositePropagatorModule,
} from '@jufab/opentelemetry-angular-interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Theme & Settings
import { fengTheme } from '../theme';
import { fengSettings } from '../settings';
import { fengEnv } from '../env';

// FengST
import { NgxFengSTUiModule } from '@fengbrasil/ngx-fengst-ui';
import { NgxFengSTLayoutModule } from '@fengbrasil/ngx-fengst-layout';
import { NgxFengSTContactModule } from '@fengbrasil/ngx-fengst-contact';
import { JwtInterceptor, NgxFengSTAuthModule } from '@fengbrasil/ngx-fengst-auth';
import { NgxFengSTAccountModule } from '@fengbrasil/ngx-fengst-account';
import { NgxFengstExclusiveContentModule } from '@fengbrasil/ngx-fengst-exclusive-content';
import { NgxFengSTXRayModule } from '@fengbrasil/ngx-fengst-xray';
import { NgxFengSTPartnerModule } from '@fengbrasil/ngx-fengst-partner';
import { NgxFengstSecurityModule } from '@fengbrasil/ngx-fengst-security';
import { NgxFengstLandingModule } from '@fengbrasil/ngx-fengst-landing';

// Standalone Components
import { FlatvComponent } from './flatv/flatv.component';
import { PrivacyDataComponent } from './privacy-data/privacy-data.component';
import { FanMapComponent } from './fan-map/fan-map.component';
import { AppRoutingLightModule } from './app-routing-light.module';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(fengEnv.auth?.adb2c?.msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FlatvComponent,
    PrivacyDataComponent,
    FanMapComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // AppRoutingLightModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
    FormsModule,
    OpenTelemetryInterceptorModule.forRoot({
      commonConfig: {
        console: true, // Display trace on console (only in DEV env)
        production: false, // Send Trace with BatchSpanProcessor (true) or SimpleSpanProcessor (false)
        serviceName: 'Front Flamengo STG', // Service name send in trace
        probabilitySampler: '1',
      },
      otelcolConfig: {
        url: 'https://signoz.feng.rocks/v1/traces',
      },
    }),
    OtelColExporterModule,
    CompositePropagatorModule,

    NgxFengSTUiModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTAuthModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTLayoutModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTAccountModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengstExclusiveContentModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTPartnerModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTContactModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTXRayModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengstSecurityModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengstLandingModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    MsalModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: fengEnv.recaptcha.key
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: JwtInterceptor, 
      multi: true 
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
