export const fengTheme = {
    name: 'feng',
    properties: {
        '--background': '#fff',
        '--on-background': '#0A0A0A',
        '--primary': '#0A0A0A',
        '--on-primary': '#e11e28',
        '--secondary': '#D2232A',
        '--on-secondary': '#0A0A0A',
        '--surface': '#0A0A0A',
        '--on-surface': '#D2232A',
        '--error': '#F25455',
        '--on-error': '#fff',
        '--warning': '#EFC845',
        '--on-warning': '#fff',
        '--success': '#68DB6C',
        '--on-success': '#fff',
        '--info': '#D0D0D0',
        '--on-info': '#fff',
        '--primary-RGB': '65, 65, 65',
        '--primary-light': '#F8F8F8',
        '--primary-dark': '#1B1B1B',
        '--secondary-dark': '#997308',
        '--secondary-RGB': '191,146,64',
        '--surface-RGB': '0, 0, 0',
        '--error-RGB': '255, 255, 255',
        '--warning-RGB': '255, 255, 255',
        '--success-RGB': '104,219,108',
        '--info-RGB': '255, 255, 255',
        '--on-background-RGB': '0,0,0',

        '--max-width': '1200px',

        // Add external fonts @font-face to styles.scss
        '--font-primary': 'Montserrat',
        '--font-title': 'Montserrat-bold',
        '--font-button': 'Montserrat',

        // Menu
        '--menu-itens-color': '#E0E0E0',
        '--menu-itens-background': 'transparent',
        '--menu-active-color': '#fff',
        '--menu-active-background': 'var(--secondary)',
        '--menu-active-underline': '0',

        '--menu-mobile-background': '#fff',
        '--menu-mobile-user-detail-color': '#404040',
        '--menu-mobile-user-detail-background': '#fff',
        '--menu-mobile-itens-color': '#404040',
        '--menu-mobile-itens-background': '#fff',

        // Header
        '--header-margin': '',
        '--header-bg': 'transparent',
        '--header-mobile-logo-width': '72px',
        '--header-desktop-logo-width': '90px',
        // '--header-mobile-height': '130px', // when counter is enabled
        // '--header-desktop-height': '234px', // when counter is enabled
        // '--header-desktop-content-padding': '180px', // when counter is enabled
        // '--header-mobile-content-padding': '34px', // when counter is enabled
        '--header-mobile-height': '72px',
        '--header-desktop-height': '112px',
        '--header-desktop-content-padding': '0',
        '--header-mobile-content-padding': '0',
        '--header-mobile-font-size': '14px',
        '--header-desktop-font-size': '18px',
        '--header-mobile-margin-top': '0',
        '--header-desktop-margin-top': '0',
        '--header-mobile-logo-top': '-20px',
        '--header-desktop-logo-top': '-20px',
        '--public-header-security-top-padding': '0',
        '--private-header-security-top-padding': '0',
        '--header-logged-bg': '#000000 url(https://images.mengo.com.br/prod/assets/images/bg-fla-mark.png) no-repeat center center / cover',
        '--header-logged-mobile-logo-width': '60px',
        '--header-logged-desktop-logo-width': '120px',

        '--header-counter-background': 'var(--secondary)',
        '--header-counter-font-family': 'Montserrat',
        '--header-counter-text-color': 'var(--on-background)',
        '--header-counter-color': '#fff',
        '--header-counter-number-bg': 'var(--primary)',
        '--header-counter-number-border-color': 'var(--secondary)',
        '--header-counter-number-outline-color': 'var(--secondary)',
        '--header-counter-number-font-family': 'Montserrat',

        // Home Public
        '--home-public-background': "url('https://images.mengo.com.br/matchday/prod/assets/img/HomeEstadio2024/bg-pattern.png') no-repeat 100% 0 /cover",
        //Background public Home
        // Call to Action
        '--home-call-to-action-font-family': 'Montserrat-bold',
        '--home-call-to-action-background': 'no-repeat center right / cover',
        '--home-call-to-action-background-img': 'url(https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/bg-home.png)',
        '--home-call-to-action-detail-color': 'var(--secondary)',
        '--home-call-to-action-title-font-size': '50px',
        '--home-call-to-action-title-font-family': 'Montserrat-bold',
        '--home-call-to-action-title-font-weight': 'normal',
        '--home-call-to-action-title-margin': '0 0 54px',
        '--home-call-to-action-title-line-height': '114%',
        '--home-call-to-action-title-alternate-font-family': 'Montserrat',
        '--home-call-to-action-title-alternate-font-size': '110px',
        '--home-call-to-action-subtitle-font-size': '30px',
        '--home-call-to-action-subtitle-width': '590px',
        '--home-call-to-action-subtitle-line-height': '140%',
        '--home-call-to-action-content-padding': '0',
        '--home-call-to-action-content-align': 'left',
        '--home-call-to-action-button-margin': '0',
        '--home-call-to-action-button-background': 'var(--secondary)',
        '--home-call-to-action-button-padding': '8px 30px',
        '--home-call-to-action-button-font-size': '19px',
        '--home-call-to-action-button-style': 'normal',
        '--home-call-to-action-button-color': 'var(--primary)',
        '--home-call-to-action-hover-button-color': 'white',
        '--home-call-to-action-title-color': '#fff',
        '--home-call-to-action-title-desktop-max-width': '920px',
        '--mobile-home-call-to-action-title-margin': '0 0 12px',
        '--mobile-home-call-to-action-content-padding': '0',
        '--mobile-home-call-to-action-title-font-size': '25px',
        '--mobile-home-call-to-action-title-line-height': '100%',
        '--mobile-home-call-to-action-title-alternate-font-family': 'Montserrat',
        '--mobile-home-call-to-action-title-alternate-font-size': '62px',
        '--call-to-action-margin-top': '0',
        '--call-to-action-padding-top': '30px',

         '--cta-13-mobile-background': 'url("https://images.mengo.com.br/matchday/prod/assets/img/HomeEstadio2024/bg-1scroll-mobile.png") no-repeat center center / cover',
        '--cta-13-desktop-background': 'url("https://images.mengo.com.br/matchday/prod/assets/img/HomeEstadio2024/bg-1scroll-web.png") no-repeat center center/cover',
        '--cta-13-max-width-mobile': '312px',
        '--cta-13-max-width-desktop': '1105px',
        '--cta-13-text-color': '#fff',
        '--cta-13-title-color': '#fff',
        '--cta-13-mobile-title-font': 'normal normal 800 32px/39px Montserrat-bold',
        '--cta-13-desktop-title-font': 'normal normal 800 64px/70.4px Montserrat-bold',
        '--cta-13-subtitle-mobile-font': 'normal normal 400 20px/24.38px Montserrat',
        '--cta-13-subtitle-desktop-font': 'normal normal 400 24px/29.26px Montserrat',
        '--cta-13-button-primary-bg': "#F00A0A",
        '--cta-13-button-color': '#fff',
        '--cta-13-button-font': 'normal normal 700 14px/14px Montserrat-SemiBold',

        '--content-background': '#232323 url("https://images.mengo.com.br/matchday/prod/assets/img/HomeEstadio2024/home_bg_black.png")',

        //MATCHDAY-CTA
        '--matchday-cta-background-desktop': 'linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url("https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/banner-pattern-flapremiumclub.png") lightgray 50% / cover no-repeat',
        '--matchday-cta-background-mobile': 'linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url("https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/Banner-pattern-FlaPremiumClub-mobile.png") lightgray 50% / cover no-repeat',
        '--matchday-cta-button-primary-bg': '#F00A0A',
        '--matchday-cta--button-color': '#FFF',
        '--matchday-cta--max-width-desktop': '1200px',
        '--matchday-cta-title-color': '#FFF',

        // Tabs
        '--home-desktop-tabs-1-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-1-background-img': '#F8F8F8',
        '--home-desktop-tabs-2-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-2-background-img': '#F8F8F8',
        '--home-desktop-tabs-3-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-3-background-img': '#F8F8F8',

        // First Access
        '--first-access-bg': '#000000',

        // Home Private
        '--home-private-padding-top': '0',
        '--home-private-featured-height-tablet': '248px',
        '--home-private-featured-height-mobile-410': '133px',
        '--home-private-featured-height-mobile-380': '122px',

        // Plans
        '--plans-background': 'url(https://images.mengo.com.br/prod/assets/images/2023/13-bg-planos.png) no-repeat center center/cover',

        // My Profile
        '--my-profile-title-color': 'var(--primary-dark)',
        '--my-profile-name-color': 'var(--primary-dark)',

        // Contact
        '--contact-background': 'url(https://images.mengo.com.br/prod/assets/images/contact-bg.png) no-repeat center center / cover',

        // Partners
        '--partners-advantage-max-width': '100%',

        // Experiences
        '--experience-how-to-score-icon-filter': '',

        //Experiences V2.0
        '--v2-experience-cta-bg': 'url(https://images.mengo.com.br/prod/assets/images/flaexperience/hero-banner-web.png) center no-repeat',
        '--v2-experience-scroll-bg-1-2': 'url(https://images.mengo.com.br/prod/assets/images/flaexperience/bg-scroll-2-e-3-web.png) center no-repeat',
        '--v2-experience-scroll-how-bg': 'url(https://images.mengo.com.br/prod/assets/images/flaexperience/bg-scroll-4-web.png) center no-repeat',

        // Exclusive Content
        '--exclusive-content-text': 'var(--primary)',
        '--exclusive-content-background-RGB': '0,0,0',
        '--exclusive-content-background': 'white',

        // Events
        '--event-background-select': 'rgba(255, 255, 255, 0.5)',
        '--event-background-table': '#f8f8f8',
        '--event-color-button': 'var(--secondary)',
        '--event-color-text-button': 'white',
        '--event-color-text-default': '#f8f8f8',
        '--event-color-text-light': 'var(--secondary)',
        '--event-color-text-dark': '#7c7c7c',
        '--event-color-text-primary': '#545452',
        '--event-color-text-secondary': 'var(--secondary)',

        // X-Ray
        '--x-ray-text-color': '#000',
        '--x-ray-call-to-action-background': 'url(https://images.mengo.com.br/prod/assets/images/xraybg.png) no-repeat center center / cover',
        '--x-ray-call-to-action-background-img': 'url(https://images.mengo.com.br/prod/assets/images/xraybg.png)',
        '--x-ray-action-active-background-color': '#000',
        '--x-ray-tabgroup-background': 'white',
        '--x-ray-action-not-active-background-color': '#414141',
        '--x-ray-tab-header-top-position': '-30px',
        '--x-ray-tab-header-ink-bar-height': '0',
        '--x-ray-chart-colors': [
            'rgba(0,0,0)',
            'rgba(210, 35, 42)',
            'rgba(43, 45, 48)',
            'rgb(201, 81, 85)',
            'rgb(240, 241, 242)'
        ],
        '--x-ray-map-fill-color': '#1b1b1b',
        '--x-ray-map-fill-hover-color': '#333333',
        '--x-ray-map-stroke-hover-color': '#D2232A',
        '--x-ray-map-tooltip-background': 'rgba(0, 0, 0, 1.0)',
        '--x-ray-map-tooltip-border': '2px solid rgba(0, 0, 0, 0.7)',
        '--x-ray-map-tooltip-color': '#fff',
        '--x-ray-transparency-background-color': 'rgba(0, 0, 0, 1.0)',
        '--x-ray-transparency-on-background-color': '#fff',

        // Footer
        '--footer-item-margin': '25px auto',
        '--footer-color': 'white',
        '--footer-background': '#232323 url(https://images.mengo.com.br/prod/assets/images/2023/13-bg-planos.png) no-repeat center center / cover',
        '--footer-unlogged-background': '#232323 url("https://images.mengo.com.br/matchday/prod/assets/img/HomeEstadio2024/home_bg_black.png") repeat center 13px / cover',
        '--footer-premium-background': '#343434',
        '--footer-logo-width': '112px',
        '--footer-unlogged-logo-width': '64px',
        '--footer-social-bg': 'var(--secondary)',
        '--footer-social-color': '#232323',
        '--footer-social-unlogged-bg': '#fff',
        '--footer-social-unlogged-color': '#1d1d1d',

        // Auth Modal
        '--auth-modal-stlogo-width': '180px',
        '--auth-modal-stlogo-bg': '#000000',
        '--auth-modal-closebtn-color': 'black',
        '--mobile-auth-modal-closebtn-color': 'white',
        '--mobile-auth-modal-stlogo-width': '100px',
        '--mobile-auth-modal-stlogo-margin': '10px 0',

        // Pending E-mail
        '--pending-mail-bg': 'white',
        '--pending-mail-color': 'black',
        '--pending-mail-logo-height': '100px',
        '--pending-mail-logo-bg': 'white',

        // Staging
        '--staging-logo-background-color': '#000000',

         // Census
         '--census-bg-frame': 'center / cover repeat url("https://images.mengo.com.br/prod/assets/images/flaid/pesquisa-pattern.png")',

         '--census-intro-bg': 'url("https://images.mengo.com.br/prod/assets/images/flaid/detalhe-flaid.svg") left 22px top 30px no-repeat, linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-register-bg': 'url("https://images.mengo.com.br/prod/assets/images/flaid/detalhe-flaid.svg") left 22px top 30px no-repeat, linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-token-bg': 'url("https://images.mengo.com.br/prod/assets/images/flaid/detalhe-flaid.svg") left 22px top 30px no-repeat, linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-research-questions-bg': 'url("https://images.mengo.com.br/prod/assets/images/flaid/detalhe-flaid.svg") left 22px top 30px no-repeat, linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-research-success-bg': 'url("https://images.mengo.com.br/prod/assets/images/flaid/detalhe-flaid.svg") left 22px top 30px no-repeat, linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-carousel-bg': 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 12.16%, rgba(0, 0, 0, 0.90) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), center / cover no-repeat url("https://ngx-feng.s3.sa-east-1.amazonaws.com/images/modules/LOY/census/Imagem-Scroll-Beneficios.png")',

         '--census-privacy-bg': 'url("https://images.mengo.com.br/prod/assets/images/flaid/detalhe-flaid.svg") left 22px top 30px no-repeat, linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-term-bg': 'url("https://images.mengo.com.br/prod/assets/images/flaid/detalhe-flaid.svg") left 22px top 30px no-repeat, linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-register-mobile-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-intro-mobile-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-token-mobile-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-research-questions-mobile-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-research-success-mobile-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-footer-border-top': '1px solid #393939',

         '--census-privacy-mobile-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         '--census-term-mobile-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat  url("https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png")',

         // Hotsite
        '--hotsite-home-bg-scroll-1': ' linear-gradient(180deg, rgba(41, 34, 34, 0) 0%, rgba(22, 19, 19, 0.9) 90%), no-repeat center center/cover url(https://images.mengo.com.br/prod/assets/images/flaid/bg-principal.png)',
        '--hotsite-home-bg-scroll-2': 'no-repeat center center/cover url(https://images.mengo.com.br/prod/assets/images/flaid/bg-beneficio.png)',
        '--hotsite-home-bg-scroll-3': 'no-repeat center center/cover url(https://images.mengo.com.br/prod/assets/images/flaid/pattern.png)',
        '--hotsite-home-bg-content-scroll-3': 'no-repeat center center/cover url(https://images.mengo.com.br/prod/assets/images/flaid/bg-pesquisa.png)',
        '--hotsite-bg-header': '#F4F4F4',
        '--hotsite-title-color': '#FFF',
        '--hotsite-button-color': '#F00A0A',
        '--hotsite-text-color': '#F4F4F4',
        '--hotsite-card-title-color': '#F00A0A',
        '--hotsite-card-text-color': '#727272',
        '--hotsite-bg-footer': '#F4F4F4',
        '--hotsite-border': '2px solid #F00A0A',
        '--hotsite-divider-color': '#E4E4E4',
        '--hotsite-icon-social-color': '#343434',
        '--hotsite-header-desktop-max-width': '1100px',

        //PRE HOME
        '--pre-home-background': 'url("https://images.mengo.com.br/matchday/prod/assets/img/Prehome/2024/bg-prehome.png") no-repeat center center/cover',
        '--pre-home-login-button-background': '#141414',
        '--pre-home-text-color': '#fff',
        '--pre-home-text-secondary-color': '#E4E4E4',
        '--cta-button-background': '#AD1206',
        '--cta-button-hover': '#F64542',
        '--title-background': 'transparent',
        '--background-stadio-plans': 'url(https://images.mengo.com.br/matchday/prod/assets/img/Prehome/2024/img-estadio.png) no-repeat center center / cover',
        '--background-digital-plans': 'url(https://images.mengo.com.br/matchday/prod/assets/img/Prehome/2024/img-flapremios.png) no-repeat center center / cover',
        '--background-sso': '#232323',

        //TODO ADICIONAR OS LINKS DO BANNER
        '--pre-home-flaid-bg-mobile': ' url("") no-repeat center center/contain',
        '--pre-home-flaid-bg-desktop': 'url("") no-repeat center center/contain',

        //PREMIUM CLUB
        '--premium-c-cta-bg': 'url(https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/1scroll-hero.png) no-repeat center center / cover',
        '--premium-c-secondary': '#F00A0A',
        '--premium-c-title-font': 'normal normal 700 32px/40px Montserrat-SemiBold',
        '--premium-c-title-font-desktop': 'normal normal 700 72px/normal Montserrat-SemiBold',
        '--premium-c-text-font': 'normal normal 500 16px/24px var(--font-primary)',
        '--premium-c-text-font-desktop': 'normal normal 500 32px/40px var(--font-primary)',
        '--premium-c-about-text-font': 'normal normal 400 16px/24px var(--font-primary)',
        '--premium-c-about-text-font-desktop': 'normal normal 400 20px/32px var(--font-primary)',
        '--premium-c-about-text-color': '#EFEFEF',
        '--premium-c-about-bg': '#262626 url(https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/2-scroll-bg.png) no-repeat center center / cover',
        '--premium-c-content-title-font': 'normal normal 700 24px/normal Montserrat-SemiBold',
        '--premium-c-content-title-font-desktop': 'normal normal 700 56px/normal Montserrat-SemiBold',

    },
    images: {
        'censusLogo': 'https://images.mengo.com.br/prod/assets/images/flaid/flaid-branco.svg',
        'censusStLogo': 'https://images.mengo.com.br/prod/assets/images/club-shield.png',
        'stLogo': 'https://images.mengo.com.br/prod/assets/images/logo-st-nova.png',
        'stLogoMobile': 'https://images.mengo.com.br/prod/assets/images/logo-st-nova.png',
        'stLogoFooter': 'https://images.mengo.com.br/prod/assets/images/logo-st-nova.png',
        'stUnloggedLogoFooter': 'https://images.mengo.com.br/prod/assets/images/club-shield.png',
        'loadingLogo': 'https://images.mengo.com.br/prod/assets/images/logo-st-nova.png',
        'stShield': 'https://images.mengo.com.br/prod/assets/images/club-shield.png',
        'stShieldAlt': 'https://images.mengo.com.br/prod/assets/images/shield-mark.png',
        'stShieldAltRed': 'https://images.mengo.com.br/prod/assets/images/2024/flapremiumclub/crf-emblema.svg',
        '--homePlansBG': 'linear-gradient(180deg, #F8F8F8 0%, #000000 100%)',
        '--homeTicketsBG': 'url(https://images.mengo.com.br/prod/assets/images/bg-events.png)',
        '--homeExclusiveContentBG': 'url(https://images.mengo.com.br/prod/assets/images/bg-events.png)',
        'mailPendingLogo': 'https://images.mengo.com.br/prod/assets/images/logo-st-nova.png',
        header: {
            'stShieldHeader': '',
        },
        experiences: {
            'catalogBannerBackground': 'https://images.mengo.com.br/prod/assets/images/flaexperience/bg-flaexperience-web.png',
            'catalogBannerTitle': 'https://images.mengo.com.br/prod/assets/images/flaexperience/logo-flaexp-allwhite.svg',
            'catalogBannerDescription': ''
        }
    },
    texts: {
        // Home
        // Call to Action
        'callToActionFeatured': '',
        'callToActionTitle': 'VOCÊ AINDA MAIS PERTO DO FLAMENGO',
        'callToActionDetail': 'Viva experiências incríveis ao lado do Mais Querido com benefícios exclusivos',
        'callToActionButton': 'SEJA SÓCIO-TORCEDOR',

        // Auth
        // First Access
        'firstAccessText': 'Identificamos que este é o seu primeiro acesso ao nosso novo sistema. Para continuar, você deve ler e concordar com os termos de uso e com o aviso de privacidade!',
        // Recover Pass
        'recoverPassText': 'Preencha os campos abaixo para definir a sua nova senha.'
    }
}
